




import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Toast } from "vant";
import qs from "qs";
import wx from "weixin-js-sdk";

@Component({
  components: {},
})
export default class SelectSeatMiddlePage extends Mixins(Mixin) {
  mounted(): void {
    let query = this.$route.query;
    let from = query.from;
    if (from != "damai" && from != "pxq") {
      console.error("无法识别来源 >>> from：", from);
      this.$router.go(-3);
      return;
    }

    console.log("");
    console.log("query", query);
    console.log("location", location);

    // 大麦/票星球跳转过来的
    let searchParam = location.search
      ? qs.parse(location.search.replace("?", ""))
      : null;
    if (!searchParam) {
      console.error("无法获取参数 >>> searchParam：", searchParam);
      this.$router.go(-3);
    }

    console.log("searchParam", searchParam);
    let productSource = from == "damai" ? "DM" : "PXQ";

    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    switch (query.channel) {
      case "plat_wechat_miniapp":
        // 如果是小程序环境，需要到小程序锁座，因为锁座需要登录，不能在 H5 登录
        wx.miniProgram.redirectTo({
          url:
            "/pagesIndex/lockSeat/lockSeat?productSource=" +
            productSource +
            "&token=" +
            searchParam.token +
            "&seatInfoV2=" +
            JSON.stringify(searchParam.seatInfoV2),
        });
        break;
      case "plat_ios":
        // 如果是 IOS APP 环境，需要跳回 APP
        this.jsBridge("thirdPartyBridge", searchParam, () => {
          console.error(
            'SelectSeatMiddlePage >>> mounted() >>> window.webkit.messageHandlers["thirdPartyBridge"] = ',
            window.webkit.messageHandlers["thirdPartyBridge"]
          );
        });
        break;
      case "plat_android":
        // 如果是 Android APP 环境，需要跳回 APP
        this.jsBridge("thirdPartyBridge", searchParam, () => {
          console.error(
            'SelectSeatMiddlePage >>> mounted() >>> window.android["thirdPartyBridge"] = ',
            window.android["thirdPartyBridge"]
          );
        });
        break;
      default:
        this.goToLockSeatForH5(
          JSON.parse(searchParam.seatInfoV2) as order.DamaiSeatinfoV2VO,
          String(searchParam.token)
        );
        break;
    }
  }

  /**
   * 跳转到 H5 的锁座页
   */
  goToLockSeatForH5(
    seatInfoV2: order.DamaiSeatinfoV2VO,
    seatToken: string
  ): void {
    this.$api.orderApi.ticketOrder.lockSeatChoose(
      {
        seatInfoV2,
        seatToken,
        productId: "",
        showId: 0,
      },
      ({ data }) => {
        Toast.clear();
        // 将预订单 UUID 持久化，以便进入选座页时用来删除该预订单对应的锁定的座位
        this.DeleteLockSeatModule.SET_orderUuid_PERSIST(data);
        this.goToOrderConfirm(data);
      },
      (error) => {
        Toast.clear();
        this.$toast(`${error}`);
      }
    );
  }
}
